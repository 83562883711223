import {css} from '@isceco/widget-library2/external/lit'

export default css`
  .detail-content {
    display: flex;
    flex-direction: row;
    gap: var(--isceco-space-500);
  }

  .detail-content > div {
    flex: 1;
  }

  .detail-item {
    margin: var(--isceco-space-300) 0;
  }

  .detail-item > p {
    margin: var(--isceco-space-100) 0 0;
    white-space: pre-line;
  }

  .clickable > div {
    border-collapse: separate;
    margin: calc(var(--isceco-space-200) * -1);
    padding: var(--isceco-space-200);
    border-radius: var(--isceco-space-200);
  }

  .success > div {
    background-color: var(--isceco-color-green-100);
  }

  .error > div {
    background-color: var(--isceco-color-red-100);
  }

  .warning > div {
    background-color: var(--isceco-color-yellow-100);
  }


  @media (max-width: 991.98px) {
    .detail-content {
      display: block;
    }
  }
`
