import WebComponent from '../../WebComponent.js'
import {html} from '@isceco/widget-library2/external/lit'
import DetailCss from './DetailCss.js'
import ErrorUtil from '../../services/ErrorUtil.js'
import '@isceco/widget-library2/basic-elements/Link/Link.js'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/ActionMenu/ActionMenu.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'

export default class DetailWebComponent extends WebComponent {
  get css() {
    return DetailCss
  }

  renderLogs = logs => {
    let start = new Date(logs?.[0].time)
    const logEntries = []
    for (const log of logs) {
      if (log.log.startsWith('Starting elbetz check')) {
        start = new Date(log.time)
      }
      logEntries.push(`${this.renderDate(log.time)} (${new Date(log.time) - start}ms) - ${log.log}`)
    }
    return this.renderEntry('response.log', logEntries, '\n')
  }

  renderErrors = errors => {
    if (errors && errors.length > 0) {
      return errors.map(error => ([
        html`
          <isceco-title text="${this.i18n.translate('error')}" size="small"></isceco-title>`,
        this.renderEntry('errors.code', error.code),
        this.renderEntry('errors.reason', ErrorUtil.description(error.code, this.request))
      ]))
    } else {
      return '-'
    }
  }

  renderPerson = (person, prefix = '') => {
    return html`
      ${this.renderEntry(`${prefix}person.name`, person?.name)}
      ${this.renderEntry(`${prefix}person.streetAndNumber`, person?.address.streetAndNumber)}
      ${this.renderEntry(`${prefix}person.postCode.city`, [person?.address.postCode, person?.address.city])}
      ${this.renderEntry(`${prefix}person.country`, person?.address.country)}
    `
  }

  renderEntry = (name, value, separator = ' ') => {
    let valueRendered
    if (value instanceof Array) {
      valueRendered = value.join(separator).trim()
    } else {
      valueRendered = value
    }
    valueRendered = valueRendered ? valueRendered : '-'
    return html`
      <div class="detail-item">
        <b>${this.i18n.translate(name)}</b>
        <p>${valueRendered}</p>
      </div>
    `
  }

  renderDate = date => date ? formatDateTime(date) : null

  renderDownload = (allowed, id) => {
    if (this.downloadAllowed && id) {
      return html`
        <isceco-action-menu id="detail-action-menu"
                            overlap
                            .items="${[
                              {
                                icon: 'file download',
                                text: this.i18n.translate('json.download'),
                                callback: _ => this.downloadJson(id)
                              }
                            ]}">
          <isceco-button slot="wrapper" variant="tertiary" size="small" icon="ellipsis vertical"></isceco-button>
        </isceco-action-menu>

      `
    } else {
      return html``
    }
  }

  downloadJson = id => {
    this.queriesService.read(getNavigationId(), 'json')
      .then(response => response.blob())
      .then(blob => download(toValidFilename(id, 'json'), blob))
  }
}
