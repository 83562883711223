import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import DateTimeCell from '@isceco/widget-library2/basic-elements/List/cell/DateTimeCell.js'
import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'
import DropdownMultiselectColumn from '@isceco/widget-library2/basic-elements/List/column/DropdownMultiselectColumn.js'
import TypeEnum from '../../enum/TypeEnum.js'
import AnfrageStatusEnum from '../../enum/AnfrageStatusEnum.js'
import VerarbeitungStatusEnum from '../../enum/VerarbeitungStatusEnum.js'

export default class QueriesColumns {

  //   **************** CITES Export *****************
  static getCITESExportColumns() {
    return [
      this.TIME(),
      this.PERMIT_ID(),
      this.DESTINATION(),
      this.TYPE(),
      this.DEPRECIATION_AMOUNT(),
      this.DEPRECIATION_AMOUNT_UNIT(),
      this.ANFRAGE_STATUS(),
      this.STATUS(),
      this.LATIN_NAME()
    ]
  }

  static TIME() {
    return new GenericColumn({
      key: 'time',
      text: 'table.header.time',
      cell: new DateTimeCell(),
      sortable: true
    })
  }

  static PERMIT_ID() {
    return new StringColumn({
      key: 'permitId',
      text: 'table.header.permitId',
      sortable: true
    })
  }

  static DESTINATION() {
    return new StringColumn({
      key: 'destination',
      text: 'table.header.destination',
      sortable: true
    })
  }

  static TYPE() {
    return new DropdownMultiselectColumn({
      items: TypeEnum.ITEMS(),
      key: 'type',
      text: 'table.header.type',
      sortable: true
    })
  }

  static DEPRECIATION_AMOUNT() {
    return new StringColumn({
      key: 'depreciationAmount',
      text: 'table.header.depreciationAmount',
      sortable: true
    })
  }

  static DEPRECIATION_AMOUNT_UNIT() {
    return new StringColumn({
      key: 'depreciationAmountUnit',
      text: 'table.header.depreciationAmountUnit',
      sortable: true
    })
  }

  static ANFRAGE_STATUS() {
    return new DropdownMultiselectColumn({
      items: AnfrageStatusEnum.ITEMS(),
      key: 'ok',
      text: 'table.header.anfrage.status',
      sortable: true
    })
  }

  static STATUS() {
    return new DropdownMultiselectColumn({
      items: VerarbeitungStatusEnum.ITEMS(),
      key: 'status',
      text: 'table.header.status',
      sortable: true
    })
  }

  static LATIN_NAME() {
    return new StringColumn({
      key: 'latinNameStr',
      text: 'table.header.latinName',
      sortable: true
    })
  }

//   **************** EDAV *****************
  static getEDAVColumns() {
    return [
      this.TIME(),
      this.PERMIT_ID(),
      this.DISPATCH_COUNTRY(),
      this.NCT_NUMBER(),
      this.CONTROL_CODE(),
      this.NET_MASS(),
      this.ADDITIONAL_MASS(),
      this.TRANSPORT_MODE(),
      this.TYPE(),
      this.ANFRAGE_STATUS(),
      this.STATUS()
    ]
  }

  static DISPATCH_COUNTRY() {
    return new StringColumn({
      key: 'dispatch',
      text: 'table.header.dispatch',
      sortable: true
    })
  }

  static NCT_NUMBER() {
    return new StringColumn({
      key: 'nctNumber',
      text: 'table.header.national.customs.tariff.number',
      sortable: true
    })
  }

  static CONTROL_CODE() {
    return new StringColumn({
      key: 'controlCode',
      text: 'table.header.control.code',
      sortable: true
    })
  }

  static NET_MASS() {
    return new StringColumn({
      key: 'netMass',
      text: 'table.header.net.mass',
      sortable: true
    })
  }

  static ADDITIONAL_MASS() {
    return new StringColumn({
      key: 'additionalMass',
      text: 'table.header.additional.mass',
      sortable: true
    })
  }

  static TRANSPORT_MODE() {
    return new StringColumn({
      key: 'transportMode',
      text: 'table.header.transport.mode',
      sortable: false
    })
  }

  //   **************** LMR *****************
  static getLMRColumns() {
    return this.getEDAVColumns()
  }

//   **************** IUU *****************
  static getIUUColumns() {
    return [
      this.TIME(),
      this.DISPATCH_COUNTRY(),
      this.ORIGIN_COUNTRY(),
      this.REFERNECE_NUMBER(),
      this.PERMIT_ID(),
      this.NCT_NUMBER(),
      this.NET_MASS(),
      this.TYPE(),
      this.ANFRAGE_STATUS(),
      this.STATUS(),
      this.IMPORTER(),
      this.DECLARANT()
    ]
  }

  static ORIGIN_COUNTRY() {
    return new StringColumn({
      key: 'origin',
      text: 'table.header.origin',
      sortable: true
    })
  }

  static REFERNECE_NUMBER() {
    return new StringColumn({
      key: 'referenceNumber',
      text: 'table.header.goods.declaration.reference.number',
      sortable: true
    })
  }

  static IMPORTER() {
    return new StringColumn({
      key: 'importerName',
      text: 'table.header.importer',
      sortable: true
    })
  }

  static DECLARANT() {
    return new StringColumn({
      key: 'declarant',
      text: 'table.header.declarant',
      sortable: true
    })
  }

}
