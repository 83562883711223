import WebComponent from '../../../WebComponent.js'
import {html} from '@isceco/widget-library2/external/lit'
import ModuleColumns from '../../Queries/QueriesColumns.js'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/List/List.js'
import '@isceco/widget-library2/basic-elements/Filter/DateFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/StringFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/MultiselectFilter.js'
import QueriesService from '../../../services/QueriesService.js'
import QueriesRowVariant from '../../Queries/QueriesRowVariant.js'
import QueriesListService from '../../Queries/QueriesListService.js'
import ErrorUtil from '../../../services/ErrorUtil.js'
import ModuleEnum from '../../../enum/ModuleEnum.js'
import TypeEnum from '../../../enum/TypeEnum.js'
import AnfrageStatusEnum from '../../../enum/AnfrageStatusEnum.js'
import VerarbeitungStatusEnum from '../../../enum/VerarbeitungStatusEnum.js'

export default class IUU extends WebComponent {
  get translationFile() {
    return './views/IUU/Queries/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.module = ModuleEnum.KEYS.IUU
    this.reload()
  }

  reload() {
    const queriesService = new QueriesService()

    Promise.all([
      queriesService.getBackendListUrl(),
      queriesService.list(`dispatches/${this.module}`),
      queriesService.list(`origins/${this.module}`),
      queriesService.list(`responseerrors/${this.module}`)
    ])
      .then(([url, dispatches, origins, errors]) => {
        this.listUrl = url
        this.dispatches = dispatches.map(dispatch => ({
          value: dispatch,
          name: dispatch
        }))
        this.origins = origins.map(origin => ({
          value: origin,
          name: origin
        }))
        this.errors = ErrorUtil.ITEMS(errors)
        this.render()
      })
  }

  getTemplate() {
    return html`
      <isceco-title text="${this.i18n.translate('page.title')}"></isceco-title>
      <isceco-filter-group>
        <isceco-date-filter
          label="${this.i18n.translate('table.header.time')}"
          key="time">
        </isceco-date-filter>
        <isceco-multiselect-filter
          .items="${this.dispatches}"
          label="${this.i18n.translate('table.header.dispatch')}"
          key="dispatch">
        </isceco-multiselect-filter>
        <isceco-multiselect-filter
          .items="${this.origins}"
          label="${this.i18n.translate('table.header.origin')}"
          key="countryOfOrigin">
        </isceco-multiselect-filter>
        <isceco-string-filter
          label="${this.i18n.translate('table.header.goods.declaration.reference.number')}"
          key="goodsDeclarationReferenceNumber"
          minchars="2">
        </isceco-string-filter>
        <isceco-string-filter
          label="${this.i18n.translate('table.header.permitId')}"
          key="permitId"
          minchars="2">
        </isceco-string-filter>
        <isceco-string-filter
          label="${this.i18n.translate('table.header.national.customs.tariff.number')}"
          key="nctNumber"
          minchars="2">
        </isceco-string-filter>
        <isceco-string-filter
          label="${this.i18n.translate('table.header.net.mass')}"
          key="netMass"
          minchars="2">
        </isceco-string-filter>
        <isceco-string-filter
          label="${this.i18n.translate('table.header.zollstelle')}"
          key="zollstelle"
          minchars="99">
        </isceco-string-filter>
        <isceco-string-filter
          label="${this.i18n.translate('table.header.transport.mode')}"
          key="transportMode"
          minchars="99">
        </isceco-string-filter>
        <isceco-multiselect-filter
          .items="${TypeEnum.ITEMS()}"
          label="${this.i18n.translate('table.header.type')}"
          key="type">
        </isceco-multiselect-filter>
        <isceco-multiselect-filter
          .items="${AnfrageStatusEnum.ITEMS()}"
          label="${this.i18n.translate('table.header.anfrage.status')}"
          key="ok">
        </isceco-multiselect-filter>
        <isceco-multiselect-filter
          .items="${VerarbeitungStatusEnum.ITEMS()}"
          label="${this.i18n.translate('table.header.status')}"
          key="status">
        </isceco-multiselect-filter>
        <isceco-multiselect-filter
          .items="${this.errors}"
          label="${this.i18n.translate('table.header.errors')}"
          key="errors">
        </isceco-multiselect-filter>
        <isceco-string-filter
          label="${this.i18n.translate('table.header.importer')}"
          key="importerName"
          minchars="2">
        </isceco-string-filter>
        <isceco-string-filter
          label="${this.i18n.translate('table.header.declarant')}"
          key="declarant"
          minchars="99">
        </isceco-string-filter>
      </isceco-filter-group>

      <isceco-list id="queries-table"
                   .i18n="${this.i18n}"
                   .columns="${ModuleColumns.getIUUColumns()}"
                   .rowVariant="${new QueriesRowVariant()}"
                   .rowurl="${this._toDetail}"
                   .listService="${new QueriesListService(this.listUrl, this.module, [330])}"
                   sort="time"
      ></isceco-list>
    `
  }

  _toDetail = dto => `IUUDetail/${dto.id}`
}

customElements.define('management-frontend-iuu-queries', IUU)
