import TranslatedEnum from './TranslatedEnum.js'

export default class ModuleEnum extends TranslatedEnum {
  static get KEYS() {
    return {
      CITES_EXPORT: 'CITES_EXPORT',
      EDAV: 'EDAV',
      IUU: 'IUU'
    }
  }

  static get I18N() {
    return {
      de: {
        CITES_EXPORT: 'CITES Export',
        EDAV: 'EDAV/LMR',
        IUU: 'IUU'
      },
      fr: {
        CITES_EXPORT: 'Exportation CITES',
        EDAV: 'EDAV/LMR fr',
        IUU: 'IUU fr'
      },
      it: {
        CITES_EXPORT: 'Esportazione CITES',
        EDAV: 'EDAV/LMR it',
        IUU: 'IUU it'
      }
    }
  }

  static EDAV_ITEMS() {
    return this.formatItems([ModuleEnum.KEYS.EDAV])
  }
}
