fetch('./config/config.json', {cache: 'reload'})
  .then(res => res.json())
  .then(config => {
    import(/* @vite-ignore */config.ssoKeycloakAdapter)
      .then(() => {
        window.keycloak = new Keycloak({
          url: config.ssoUrl,
          realm: config.ssoRealm,
          clientId: config.ssoClient
        })
        window.keycloak.init({
          onLoad: 'login-required',
          promiseType: 'native',
          checkLoginIframe: false,
          pkceMethod: 'S256'
        }).then(authenticated => {
          document.querySelector('body').innerHTML = `
<main>
  <div id="mainheader">
    <management-frontend-header></management-frontend-header>
    <management-frontend-horizontal-navigation></management-frontend-horizontal-navigation>
  </div>
  <div id="maincontent">
    <isceco-message lifetime="10"></isceco-message>
    <management-frontend-main-view></management-frontend-main-view>
    <isceco-scroll-top></isceco-scroll-top>
  </div>
  <div id="mainfooter">
    <management-frontend-footer></management-frontend-footer>
  </div>
</main>
                  `
        })

        // used for Global Logout context
        window.keycloak.onTokenExpired = () => {
          keycloak.updateToken(-1)
            .catch(function () {
              console.error('Failed to refresh the token, or the session has expired') // NOSONAR
            })
        }
      })
  })
